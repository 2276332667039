<template>
  <div class="table-filters pt-1 mb-2">
    <h5 class="table-header ">أكثر المحررين كتابة</h5>
    <DatesFilterDropDown defaultKey="منذ 7 أيام" @triggerFilter="fetchMostActiveEditors"/>
  </div>
  <el-table id="top-table" :data="editors_list" class="mb-4" stripe style="width: 100%" max-height="850"
            v-loading="loading">
    <el-table-column type="index" label="#" width="50"/>
    <el-table-column prop="editor" width="300" class-name="text-end" label="المحرر">
      <template #default="scope">
      {{ scope.row.display_name }}
      </template>
    </el-table-column>
    <el-table-column prop="editor" align="center" label="عدد المقالات" width="120">
      <template #default="scope">
        {{ scope.row.count }}
      </template>
    </el-table-column>
  </el-table>
</template>



<script setup>
import {onMounted, ref} from 'vue';
import {fetchEditors} from "@/services/dashboard";
import DatesFilterDropDown from './DatesFilterDropDown';

const editors_list = ref([])
const loading      = ref(true);

const fetchMostActiveEditors = async (key) => {
  loading.value = true
  let modifiedKey = key === 'lastMonth' ? 'last_month' : key;

  try
  {
    const result       = await fetchEditors(modifiedKey);
    editors_list.value = await result.data
    loading.value      = false;
  } catch (e)
  {
    loading.value = false;

  }
}

onMounted(() => fetchMostActiveEditors('week'))
</script>

<style lang="scss">
.filter_btn {
  padding-inline-end: 5px;

  & > span {
    gap: 1rem;
    padding: 5px 0;

    span {
      height: 18px;
    }
  }
}
</style>
